<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="msaBlue white--text">
          Select Task Groups</v-card-title
        >
        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="6">
                <SearchBar
                  ref="searchBar"
                  @search="onSearch"
                  searchLabel="Search by group name..."
                  :disabled="isLoading"
                />
              </v-col>
            </v-row>
            <v-row v-if="isLoading">
              <v-col>
                <v-progress-linear
                  indeterminate
                  color="msaBlue"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  v-if="filteredGroups.length"
                  v-model="selected"
                  show-select
                  :headers="headers"
                  :items="filteredGroups"
                  disable-pagination
                  hide-default-footer
                  disable-filtering
                  :disable-sort="isMobile"
                  checkbox-color="msaBlue"
                  item-key="id"
                  class="custom-list"
                  :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
                >
                </v-data-table>

                <div v-else>
                  <span v-if="!isLoading"
                    >There are no documents that match your search</span
                  >.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" class="mr-2" text @click="close"> cancel </v-btn>
          <v-badge
            color="green"
            :content="selected.length"
            :value="selected.length"
            left
          >
            <v-btn
              class="msaBlue white--text"
              @click="importSelected"
              :disabled="!selected.length"
              >Import Selected
            </v-btn>
          </v-badge>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip, attrs }">
        <v-btn
          color="msaBlue"
          v-bind="attrs"
          v-on="tooltip"
          v-blur
          class="white--text"
          @click="show"
          data-testid="import-task-groups"
        >
          <v-icon>mdi-plus-box-multiple-outline</v-icon>
        </v-btn>
      </template>
      <span>Import Task Groups</span>
    </v-tooltip>
  </div>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';

export default {
  components: { SearchBar },
  data() {
    return {
      groups: [],
      dialog: false,
      search: '',
      isLoading: false,
      selected: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Number of Tasks',
          value: 'count',
          class: 'lightGrey',
          width: '130px',
          align: 'center',
        },
      ],
    };
  },
  watch: {
    dialog(nv) {
      if (nv) {
        this.getGroups();
      } else {
        this.clear();
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  computed: {
    isSearching() {
      if (!this.search) {
        return false;
      }
      return this.search.trim().length > 0;
    },
    filteredGroups() {
      return this.groups;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    importSelected() {
      if (!this.selected.length) {
        close();
      }
      this.isLoading = true;
      let selectedGroups = this.selected.map((g) => g.id);
      const params = {
        loaderText: 'Importing....',
        taskGroupTemplateIds: selectedGroups,
        userId: this.$route.params.id,
      };
      this.$axios
        .post('import-task-group-templates?format=json', params)
        .then(() => {
          this.isLoading = false;
          this.$emit('imported', {});
          this.close();
        });
    },
    onSearch(newSearch) {
      this.search = newSearch;
      this.getGroups();
    },
    getGroups() {
      this.isLoading = true;
      const params = {
        userId: this.$route.params.id,
        name: this.search,
      };
      this.$axios
        .post('get-task-group-templates-for-import?format=json', params)
        .then((response) => {
          this.isLoading = false;
          this.groups = response.data;
        });
    },
    show() {
      this.dialog = true;
    },
    clear() {
      (this.selected = []), (this.groups = []);
      this.search = '';
      this.$refs.searchBar.setSearch('');
    },
    close() {
      this.clear();
      this.dialog = false;
    },
  },
};
</script>

<style>
.custom-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
