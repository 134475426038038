<template>
  <v-container fluid :class="selected.length > 0 ? 'pb-16' : ''">
    <v-row>
      <v-col cols="12" md="4">
        <SearchBar
          ref="searchBar"
          v-if="!isOrdering"
          @search="onSearch"
          searchLabel="Search by task name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink"> </v-col>
      <v-col class="shrink"> </v-col>
      <v-col class="shrink">
        <TaskFilter @applyFilters="onApplyFilters" ref="filters" />
      </v-col>
    </v-row>
    <v-row v-if="isLoading">
      <v-col>
        <v-progress-linear indeterminate color="msaBlue"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="resultMessage.length">
      <v-col> {{ resultMessage }} </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <TaskGroups
          v-if="showGroups"
          :groups="groups"
          :isOrdering="isOrdering"
          :isSearching="isSearching"
          @updateGroup="onGroupUpdated"
          @addGroup="onGroupAdded"
          @deleteGroup="deleteGroup"
          @isOrdering="isOrdering = true"
          @onOrder="changeGroupOrder"
          @updateGroupPositions="updateGroupPositions"
          @goToGroup="goToGroup($event)"
          @imported="onImported"
        />
      </v-col>
      <v-col cols="12" md="8">
        <v-expansion-panels v-model="expanded" multiple>
          <v-expansion-panel v-for="group in groups" :key="group.id">
            <v-expansion-panel-header
              class="msaGrey"
              hide-actions
              v-slot="{ open }"
              :ref="'group' + group.id"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink">
                  <v-icon v-if="open">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col
                  class="grow pl-4 font-weight-medium"
                  :data-testid="`${group.name}`"
                >
                  {{ group.name }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <UserTasksTable
                :group="group"
                :selected="selected"
                :isOrdering="isOrdering"
                :isSearching="isSearching"
                :checkSelectedTrigger="checkSelectedTrigger"
                @taskDeleted="onTaskDeleted(group, $event)"
                @isOrdering="isOrdering = true"
                @updateTaskPositions="updateTaskPositions(group)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="isSearching && groups.length == 0">
      <v-col cols="12" align="center">No results found</v-col>
    </v-row>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import TaskGroups from '@/components/TrainingTasks/TaskGroups.vue';
import UserTasksTable from '@/components/TrainingTasks/UserTasksTable.vue';
import TaskFilter from '@/components/TrainingTasks/TaskFilter.vue';
export default {
  name: 'UserTasksPage',
  components: {
    TaskGroups,
    UserTasksTable,
    SearchBar,
    TaskFilter,
  },
  data() {
    return {
      userId: null,
      filters: {
        ...this.$constants.TASK_FILTER,
      },
      taskGroups: [],
      taskSearch: '',
      search: '',
      isOrdering: false,
      expanded: [],
      selected: [],
      checkSelectedTrigger: 1,
      isLoading: false,
    };
  },
  computed: {
    showGroups() {
      return (
        !(this.isFilterApplied && this.groups.length == 0) && !this.isLoading
      );
    },
    resultMessage() {
      if (this.isFilterApplied && !this.isLoading && this.groups.length == 0) {
        return 'There are no tasks matching your search.';
      }
      if (!this.isLoading && this.groups.length == 0) {
        return 'Use the Add Task Group button to add a task group.';
      }
      return '';
    },
    isSearching() {
      return this.taskSearch.length > 0;
    },
    groups() {
      return this.taskGroups;
    },
    isFilterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.TASK_FILTER)
      );
    },
  },
  methods: {
    onImported() {
      this.$router.go();
    },
    onSearch(data) {
      this.filters.taskName = data;
      this.applyFilter();
    },
    onApplyFilters(filters) {
      this.filters = { ...filters };
      this.$refs.searchBar.search = filters.taskName;
      this.applyFilter();
    },
    getTasks() {
      this.isLoading = true;
      const params = {
        userId: this.userId,
        isFilterApplied: this.isFilterApplied,
        filters: this.filters,
      };
      this.$axios
        .post('get-task-groups-by-user?format=json', params)
        .then((response) => {
          this.isLoading = false;
          this.taskGroups = response.data.groups;
          this.expandAll();
          this.$store.commit('updateSelectedUser', {
            ...response.data.user,
            status: response.data.user.status == 'Active' ? 1 : 0,
          });
        })
        .catch((error) => error);
    },
    onGroupAdded(newGroup) {
      this.taskGroups.push(newGroup);
      this.expandAll();
    },
    onGroupUpdated(updatedGroup) {
      this.taskGroups.some((g) => {
        if (g.id == updatedGroup.id) {
          g.name = updatedGroup.name;
          return true;
        }
        return false;
      });
      this.expandAll();
    },
    expandAll() {
      this.$nextTick(() => {
        this.expanded = this.groups.map((_, i) => i);
      });
    },
    deleteGroup(groupId) {
      this.taskGroups = this.taskGroups.filter((g) => g.id != groupId);
    },
    changeGroupOrder(event) {
      const movedItem1 = { ...this.taskGroups[event.index] };

      const movedItem2 = {
        ...this.taskGroups[event.index + event.value],
      };

      this.$set(this.taskGroups, event.index, movedItem2);
      this.$set(this.taskGroups, event.index + event.value, movedItem1);
    },
    updateGroupPositions() {
      const groups = this.taskGroups.map((group) => {
        return group.id;
      });
      const params = {
        loaderText: 'Updating position...',
        taskGroupIds: groups,
        userId: this.$route.params.id,
      };

      this.$axios
        .post('update-task-group-positions', params)
        .then(() => (this.isOrdering = false))
        .catch((error) => error);
    },
    updateTaskPositions(group) {
      const taskIds = group.tasks.map((ft) => {
        return ft.id;
      });

      const params = {
        loaderText: 'Updating positions...',
        taskIds: taskIds,
        taskGroupId: group.id,
      };
      this.$axios
        .post('update-task-positions?format=json', params)
        .then(() => {
          this.isOrdering = false;
          this.checkSelectedTrigger++;
        })
        .catch((error) => error);
    },
    goToGroup(groupId) {
      const options = {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      };
      this.$vuetify.goTo(this.$refs[`group${groupId}`][0], options);
    },
    onTaskDeleted(group, taskId) {
      const index = group.tasks.findIndex((f) => f.id == taskId);
      group.tasks.splice(index, 1);
    },
    loadQueryParams() {
      this.userId = this.$route.params.id;
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(
        query,
        this.$constants.TASK_FILTER,
      );
      this.$refs.searchBar.search = this.filters.taskName;
      this.$refs.filters.setFilters(this.filters);
      this.getTasks();
    },
    applyFilter() {
      let params = this.$helpers.serializeFilter(this.filters);
      this.$router
        .push({
          name: this.$route.name,
          params: { id: this.userId },
          query: params,
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadQueryParams();
  },
};
</script>
