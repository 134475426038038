<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="group.tasks"
        disable-pagination
        hide-default-footer
        disable-filtering
        :disable-sort="isMobile"
        checkbox-color="msaBlue"
        item-key="id"
        class="custom-list"
        :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
      >
        <template v-slot:[`item.taskTypeId`]="{ item }">
          <v-checkbox
            v-if="item.taskTypeId == $constants.TASK_TYPES.REGULAR"
            dense
            hide-details
            class="ma-0 pl-2"
            v-model="item.isDone"
            :true-value="1"
            :false-value="0"
            :disabled="$store.getters.selectedUser.status == 0"
            @change="updateIsDone(item)"
          >
          </v-checkbox>
          <v-icon :color="iconColor(item)" v-else>
            {{ getIcon(item) }}
          </v-icon>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span v-html="taskName(item)"></span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div
            :class="statusClass(item.status)"
            :style="{ 'border-radius': '5px' }"
          >
            {{ item.status }}
          </div>
        </template>
        <template v-slot:[`item.dueDate`]="{ item }">
          <span :class="taskDueDateClass(item)">
            {{ item.dueDate | filterAsLocalDate }}
          </span>
        </template>
        <template v-slot:[`item.completedAt`]="{ item }">
          {{ item.completedAt | filterAsLocalDate }}
        </template>
        <template v-slot:[`item.actions`]="{ item, index }">
          <span v-if="!isOrdering">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="goToDetail(item)"
                  icon
                  small
                  color="msaBlue"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!isSearching">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  small
                  v-on="on"
                  @click="
                    $emit('isOrdering');
                    item.isOrdering = true;
                  "
                >
                  <v-icon> mdi-swap-vertical </v-icon>
                </v-btn>
              </template>
              <span>Change Position</span>
            </v-tooltip>
            <TaskDeleteDialog
              :task="item"
              @deleted="$emit('taskDeleted', item.id)"
            />
          </span>
          <span v-if="isOrdering && !item.isOrdering"></span>
          <span v-if="item.isOrdering" class="pl-0 pr-1" align="right">
            <ChangeOrderButtonGroup
              :currentIndex="index"
              :maxIndex="group.tasks.length - 1"
              @updatePositions="onUpdatePositions(item)"
              @onOrder="changeOrder($event)"
            />
          </span>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="!isOrdering">
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="group.taskGroupTemplateId == -1">
        <template v-slot:activator="{ on }">
          <v-btn
            small
            class="msaBlue white--text"
            v-on="on"
            @click="goToNew(group.id)"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        <span>Add Task</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>
<script>
import TaskDeleteDialog from '@/components/TrainingTasks/TaskDeleteDialog.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
import { DateTime } from 'luxon';
export default {
  name: 'UserTasksTable',
  components: {
    TaskDeleteDialog,
    ChangeOrderButtonGroup,
  },
  props: {
    group: {
      type: Object,
    },
    isOrdering: {
      type: Boolean,
    },
    isSearching: {
      type: Boolean,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Type',
          align: 'center',
          value: 'taskTypeId',
          class: 'lightGrey',
          width: '20px',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: '120px',
          class: 'lightGrey',
        },
        {
          text: 'Due Date',
          value: 'dueDate',
          align: 'center',
          width: '110px',
          class: 'lightGrey',
        },
        {
          text: 'Completed Date',
          value: 'completedAt',
          align: 'center',
          width: '140px',
          class: 'lightGrey',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          sortable: false,
          width: '120px',
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    statusClass() {
      return (status) => {
        let classes = '';
        switch (status) {
          case 'Completed':
            classes = 'green';
            break;
          case 'Overdue':
            classes = 'red';
            break;
          case 'Outstanding':
            classes = 'orange';
            break;
          default:
            classes = '';
            break;
        }

        classes += ' white--text';
        return classes;
      };
    },
    isTaskOverDue() {
      return (dueDate) => {
        let localDueDate = this.$options.filters.filterAsLocalDate(dueDate);
        localDueDate = DateTime.fromISO(localDueDate);
        const now = DateTime.local();
        return localDueDate < now;
      };
    },
    taskDueDateClass() {
      return (task) => {
        return this.isTaskOverDue(task.dueDate) && task.completedAt == ''
          ? 'red--text'
          : '';
      };
    },
    taskName() {
      return (task) => {
        return '<span>' + this.$helpers.linkify(task.name) + '</span>';
      };
    },
  },
  methods: {
    iconColor(task) {
      let color = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          color = 'red';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          color = 'msaBlue';
          break;
        default:
          color = 'green';
      }
      return color;
    },
    getIcon(task) {
      let icon = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          icon = 'mdi-file-pdf-box';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          icon = 'mdi-school';
          break;
        default:
          icon = '';
      }
      return icon;
    },
    goToNew(groupId) {
      //this is here so the crumbs don't look wonky on the next page
      this.$store.commit('setSelectedTaskName', '');
      this.$router.push({
        name: 'UserTaskNew',
        params: { id: this.$route.params.id, groupId: groupId },
      });
    },
    goToDetail(task) {
      //this is here so the crumbs don't look wonky on the next page
      this.$store.commit('setSelectedTaskName', task.name);
      this.$router.push({
        name: 'UserTaskDetail',
        params: { taskId: task.id },
      });
    },
    onUpdatePositions(item) {
      this.$emit('updateTaskPositions');
      item.isOrdering = false;
    },
    changeOrder(event) {
      const movedItem1 = { ...this.group.tasks[event.index] };
      const movedItem2 = {
        ...this.group.tasks[event.index + event.value],
      };

      this.$set(this.group.tasks, event.index + event.value, movedItem1);
      this.$set(this.group.tasks, event.index, movedItem2);
    },
    updateIsDone(task) {
      const params = {
        loaderText: 'loading...',
        id: task.id,
        snackText: 'Task updated.',
      };
      const url = 'update-task-is-done?format=json';
      this.$axios.post(url, params).then((response) => {
        task.completedAt = response.data.completedAt;
        task.status = response.data.status;
        task.updatedAt = response.data.updatedAt;
        task.updater = response.data.updater;
      });
    },
  },
};
</script>

<style>
.custom-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
