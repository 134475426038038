<template>
  <v-card>
    <v-card-title class="msaGrey"> Groups </v-card-title>
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-simple-table
            :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
          >
            <template v-slot:default>
              <colgroup>
                <col span="1" />
                <col span="1" :style="{ width: '155px' }" />
              </colgroup>
              <tbody>
                <tr v-for="(group, index) in groups" :key="group.id">
                  <td class="msaBlue--text">
                    <span
                      @click="$emit('goToGroup', group.id)"
                      :style="{ cursor: 'pointer' }"
                    >
                      {{ group.name }}
                    </span>
                  </td>
                  <td class="pl-0 pr-1" align="right" v-if="!isOrdering">
                    <EditTaskGroupDialog
                      :group="group"
                      @saved="$emit('updateGroup', $event)"
                    />
                    <v-tooltip bottom v-if="!isSearching">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-blur
                          text
                          small
                          v-on="on"
                          @click="
                            $emit('isOrdering');
                            group.isOrdering = true;
                          "
                        >
                          <v-icon> mdi-swap-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>Change Position</span>
                    </v-tooltip>
                    <TaskGroupDeleteDialog
                      :group="group"
                      @deleted="$emit('deleteGroup', group.id)"
                    />
                  </td>
                  <td v-if="isOrdering && !group.isOrdering"></td>
                  <td v-if="group.isOrdering" class="pl-0 pr-1" align="right">
                    <ChangeOrderButtonGroup
                      :currentIndex="index"
                      :maxIndex="groups.length - 1"
                      @updatePositions="
                        $emit('updateGroupPositions');
                        group.isOrdering = false;
                      "
                      @onOrder="$emit('onOrder', $event)"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-col align="right" v-if="!isOrdering">
            <v-row justify="end">
              <TaskGroupTemplateSelectorDialog
                class="mr-2 mt-2"
                @imported="onImported"
              />
              <AddTaskGroupDialog
                class="mr-2 mt-2"
                @add="$emit('addGroup', $event)"
              />
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TaskGroupDeleteDialog from '@/components/TrainingTasks/TaskGroupDeleteDialog.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
import AddTaskGroupDialog from '@/components/TrainingTasks/AddTaskGroupDialog.vue';
import EditTaskGroupDialog from '@/components/TrainingTasks/EditTaskGroupDialog.vue';
import TaskGroupTemplateSelectorDialog from '@/components/TrainingTasks/TaskGroupTemplateSelectorDialog.vue';

export default {
  components: {
    TaskGroupDeleteDialog,
    ChangeOrderButtonGroup,
    AddTaskGroupDialog,
    EditTaskGroupDialog,
    TaskGroupTemplateSelectorDialog,
  },
  props: {
    groups: {
      type: Array,
    },
    isOrdering: {
      type: Boolean,
    },
    isSearching: {
      type: Boolean,
    },
  },

  methods: {
    onImported() {
      this.$emit('imported', {});
    },
  },
};
</script>

<style></style>
